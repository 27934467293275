<template>
  <div>
      <div class="container">
        <div class="form">
          <div class="boite">
            <template v-if="this.moniteurEdits != null">
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="BM"
                @click="boiteChoice"
                v-if="this.moniteurEdits.boite == 'BM'"
                checked
              />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="BM"
                @click="boiteChoice"
                v-else-if="this.moniteurEdits.boite == 'BA,BM'"
                checked
              />
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="BM"
                @click="boiteChoice"
                v-else
              />
              <label for="vehicle1">BM</label>
              <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                value="BA"
                @click="boiteChoice"
                v-if="this.moniteurEdits.boite == 'BA,BM'"
                checked
              />
              <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                value="BA"
                @click="boiteChoice"
                v-else-if="this.moniteurEdits.boite == 'BA'"
                checked
              />
              <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                value="BA"
                @click="boiteChoice"
                v-else
              />
              <label for="vehicle2">BA</label><br />
            </template>
            <template v-else>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="BM"
                @click="boiteChoice"
              />
              <label for="vehicle1">BM</label>
              <input
                type="checkbox"
                id="vehicle2"
                name="vehicle2"
                value="BA"
                @click="boiteChoice"
              />
              <label for="vehicle2">BA</label><br />

            </template>

          </div>

          <div class="field col-md-12 col-xs-10 col-lg-11 mt-4">
            <input
              type="text"
              id="numNeph"
              name="numNeph"
              class="form-control inputsize"
              placeholder=" "
              v-model="moniteur.nom"
            />
            <label class="labelForm">Nom</label>
          </div>

          <div class="field col-md-12 col-xs-10 col-lg-11 mt-4">
            <input
              type="text"
              id="numNeph"
              name="numNeph"
              class="form-control inputsize"
              placeholder=" "
              v-model="moniteur.prenom"
            />
            <label class="labelForm">Prenom</label>
          </div>

          <div class="field col-md-12 col-xs-10 col-lg-11 mt-4">
            <input
              type="text"
              id="numNeph"
              name="numNeph"
              class="form-control inputsize"
              placeholder=" "
              v-model="moniteur.adresse"
            />
            <label class="labelForm">Adresse</label>
          </div>

          <div class="row">
            <div class="field col-md-12 col-xs-10 col-lg-5 mt-4">
              <input
                type="text"
                id="numNeph"
                name="numNeph"
                class="form-control inputsize"
                placeholder=" "
                v-model="moniteur.cp"
              />
              <label class="labelForm">CP</label>
            </div>

            <div class="field offset-lg-1 col-md-10 col-xs-10 col-lg-5 mt-4">
              <input
                type="text"
                id="numNeph"
                name="numNeph"
                class="form-control inputsize"
                placeholder=" "
                v-model="moniteur.ville"
              />
              <label class="labelForm">Ville</label>
            </div>
          </div>

          <div class="row">
            <div class="field col-md-12 col-xs-10 col-lg-5 mt-4">
              <input
                type="text"
                id="numNeph"
                name="numNeph"
                class="form-control inputsize"
                placeholder=" "
                v-model="moniteur.mail"
              />
              <label class="labelForm">Mail</label>
            </div>

            <div class="field offset-lg-1 col-md-10 col-xs-10 col-lg-5 mt-4">
              <input
                type="text"
                id="numNeph"
                name="numNeph"
                class="form-control inputsize"
                placeholder=" "
                v-model="moniteur.tel"
              />
              <label class="labelForm">Tél</label>
            </div>
          </div>
          <div class="choixlieu">
            <div class="groupe">
              <template
                v-for="i in this.$store.state.groupeLieuPlanning"
                :key="i"
              >
                <template v-if="this.groupeSelect == i">
                  <button
                    class="btnGroupe btn-primary"
                    @click="groupeChoice(i)"
                  >
                    Groupe {{ i }}
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btnGroupe btn-secondary"
                    @click="groupeChoice(i)"
                  >
                    Groupe {{ i }}
                  </button>
                </template>
              </template>
            </div>
            <div class="table">
              <DataTable :value="lieuxMono" v-if="groupeSelect == 1">
                <Column field="nom" header="Nom">
                  <template #body="{ data }">
                    <p :title="data.fulladresse">{{ data.nom }}</p>
                  </template>
                </Column>
                <Column field="Input" header="Input">
                  <template #body="{ data }">
                    <template v-if="this.moniteurEdits != null">
                      <template v-if="groupeSelect == data.groupe">
                        <template v-if="data.get">
                          <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                            checked
                          />
                        </template>
                        <template v-else>
                          <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                          />
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="data.get">
                        <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                            checked
                          />
                      </template>
                      <template v-else>
                        <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                          />
                      </template>
                    </template>
                  </template>
                </Column>
              </DataTable>

              <DataTable :value="lieuxMono" v-if="groupeSelect == 2">
                <Column field="nom" header="Nom">
                  <template #body="{ data }">
                    <p :title="data.fulladresse">{{ data.nom }}</p>
                  </template>
                </Column>
                <Column field="Input" header="Input">
                  <template #body="{ data }">
                    <template v-if="this.moniteurEdits != null">
                      <template v-if="groupeSelect == data.groupe">
                        <template v-if="data.get">
                          <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                            checked
                          />
                        </template>
                        <template v-else>
                          <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                          />
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="data.get">
                          <input
                              type="checkbox"
                              :name="data.id"
                              :id="data.id"
                              @click="lieuChoice"
                              checked
                            />
                        </template>
                        <template v-else>
                          <input
                              type="checkbox"
                              :name="data.id"
                              :id="data.id"
                              @click="lieuChoice"
                            />
                        </template>
                    </template>
                  </template>
                </Column>
              </DataTable>

              <DataTable :value="lieuxMono" v-if="groupeSelect == 3">
                <Column field="nom" header="Nom">
                  <template #body="{ data }">
                    <p :title="data.fulladresse">{{ data.nom }}</p>
                  </template>
                </Column>
                <Column field="Input" header="Input">
                  <template #body="{ data }">
                    <template v-if="this.moniteurEdits != null">
                      <template v-if="groupeSelect == data.groupe">
                        <template v-if="data.get">
                          <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                            checked
                          />
                        </template>
                        <template v-else>
                          <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                          />
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="data.get">
                        <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                            checked
                          />
                      </template>
                      <template v-else>
                        <input
                            type="checkbox"
                            :name="data.id"
                            :id="data.id"
                            @click="lieuChoice"
                          />
                      </template>
                    </template>
                  </template>
                </Column>
              </DataTable>


            </div>
          </div>
        </div>
        <div class="planningBoite">
          <div class="choiceBoite">
            <template v-if="moniteur.boite != ''">
              <template v-if="moniteur.boite.length == 4">
                <input
                  type="radio"
                  id="ba"
                  name="fav_boite"
                  value="BA"
                  @click="choixForPlanning"
                  checked
                />
                <label for="ba">BA</label>
                <input
                  type="radio"
                  id="bm"
                  name="fav_boite"
                  value="BM"
                  @click="choixForPlanning"
                />
                <label for="bm">BM</label>
              </template>
              <template v-else>
                <template v-if="moniteur.boite == 'BA'">
                  <input
                    type="radio"
                    id="ba"
                    name="fav_boite"
                    value="BA"
                    @click="choixForPlanning"
                    checked
                  />
                  <label for="ba">BA</label>
                </template>
                <template v-else>
                  <input
                    type="radio"
                    id="bm"
                    name="fav_boite"
                    value="BM"
                    @click="choixForPlanning"
                    checked
                  />
                  <label for="bm">BM</label>
                </template>
              </template>
            </template>
          </div>
          <BoiteCalendar :moniteurEdits="this.moniteurEdits"/>
        </div>
      </div>
      <button @click="setMono" class="btn btn-primary">Validé</button>
  </div>
</template>

<script>
import BoiteCalendar from "@/components/agence/planning/modals/BoiteCalendar.vue";
export default {
  props: [
    "visible",
    "moniteurs",
    "monoChecked",
    "moniteurEdits",
    "showEditMonoReset",
    "getAllMoniteurs",
    "cancelModal"
  ],

  watch: {
    visibles: function () {
      if (this.visibles == false) {
        this.$emit("showEditMonoReset");
      }
    },
  },
  components: {
    BoiteCalendar,
  },
  data() {
    return {
      visibles: false,
      moniteur: {
        nom: "",
        prenom: "",
        adresse: "",
        cp: "",
        ville: "",
        mail: "",
        tel: "",
        boite: "",
        lieux: [],
        groupe: 0,
      },
      header: "Nouveau Moniteur",
      moniteurEdit: null,
      lieuxMono: [],
      groupeSelect: 1,
      LieuxChoiceMono: [],
    };
  },

  mounted() {
    this.visibles = this.visible;
    this.$store.commit("setChoiceBoitePlanning", null);
    console.log("ergfrgergregregg", this.moniteurEdits);
    if (this.moniteurEdits != null) {
      this.header = this.moniteurEdits.fullname;
      this.moniteur = {
        nom: this.moniteurEdits.nom,
        prenom: this.moniteurEdits.prenom,
        adresse: this.moniteurEdits.adresse.adresse,
        cp: this.moniteurEdits.adresse.cp,
        ville: this.moniteurEdits.adresse.ville,
        mail: this.moniteurEdits.email,
        tel: this.moniteurEdits.telephone,
        boite: this.moniteurEdits.boite,
        lieux: this.moniteurEdits.lieux,
        groupe: this.moniteurEdits.groupeLieu,
      };
      this.lieuxMono = this.moniteur.lieux.filter((e) => e.groupe == 1);
      let lieuget = this.moniteur.lieux.filter((e) => e.get == true);
      lieuget.forEach((e) => {
        this.LieuxChoiceMono.push(e.id);
      });

      this.$store.commit("setRdvBoite", this.moniteurEdits.rdvs);

    } else {
      this.lieuxMono = this.$store.state.lieuxMonosPlanning.filter(
        (e) => e.groupe == 1
      );
      this.moniteur = {
        nom: "",
        prenom: "",
        adresse: "",
        cp: "",
        ville: "",
        mail: "",
        tel: "",
        boite: "",
        lieux: [],
        groupe: 0,
      };

    }
  },

  methods: {
    setMono() {
      if(this.moniteurEdits == null){
        window.api
        .post(
          "/set/moniteurs",
          {
            nom: this.moniteur.nom,
            prenom: this.moniteur.prenom,
            adresse: this.moniteur.adresse,
            cp: this.moniteur.cp,
            ville: this.moniteur.ville,
            mail: this.moniteur.mail,
            tel: this.moniteur.tel,
            boite: this.moniteur.boite,
            lieux: this.LieuxChoiceMono,
            agenceId: this.$store.state.user.agence.id,
            rdvs: this.$store.state.rdvBoite
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$emit("getAllMoniteurs");
          this.$emit("cancelModal");
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        window.api
        .put(
          "/edit/moniteurs/"+this.moniteurEdits.id,
          {
            nom: this.moniteur.nom,
            prenom: this.moniteur.prenom,
            adresse: this.moniteur.adresse,
            cp: this.moniteur.cp,
            ville: this.moniteur.ville,
            mail: this.moniteur.mail,
            tel: this.moniteur.tel,
            boite: this.moniteur.boite,
            lieux: this.LieuxChoiceMono,
            agenceId: this.$store.state.user.agence.id,
            rdvs: this.$store.state.rdvBoite
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$emit("getAllMoniteurs");
          this.$emit("cancelModal");
        })
        .catch((error) => {
          console.log(error);
        });
      }

    },

    lieuChoice(e) {
      if (e.target.checked) {
        this.LieuxChoiceMono.push(e.target.id);
      } else {
        let l = this.LieuxChoiceMono.filter((li) => li != e.target.id);
        this.LieuxChoiceMono = l;
      }
    },

    groupeChoice(i) {
      this.groupeSelect = i;
      if (this.moniteurEdits != null) {
        this.lieuxMono = this.moniteur.lieux.filter((e) => e.groupe == i);
      } else {
        this.lieuxMono = this.$store.state.lieuxMonosPlanning.filter(
          (e) => e.groupe == i
        );
      }
      if(this.LieuxChoiceMono.length != 0){
        this.lieuxMono.forEach(l => {
          let i = 0;
          while(i < this.LieuxChoiceMono.length){
            if(l.id.toString() == this.LieuxChoiceMono[i]){
              l.get = true;
              break;
            }else{
              l.get = false;
              i++;
            }
          }
        })
      }
    },

    choixForPlanning(e) {
      let choiceBoite = "";
      if (e.target.checked) {
        choiceBoite = e.target.value;
      } else {
        choiceBoite = "";
      }
      this.$store.commit("setChoiceBoitePlanning", choiceBoite);
    },

    boiteChoice(e) {
      if (e.target.checked) {
        this.moniteur.boite = this.moniteur.boite + e.target.value;
        console.log(this.moniteur.boite)
        this.$store.commit("setChoiceBoitePlanning", "BA");
      } else {
        if (this.moniteur.boite.length == 4) {
          if (e.target.value == "BM") {
            this.moniteur.boite = "BA";
            this.$store.commit("setChoiceBoitePlanning", "BA");
          } else {
            this.moniteur.boite = "BM";
            this.$store.commit("setChoiceBoitePlanning", "BM");
          }
        } else {
          this.moniteur.boite = "";
          this.$store.commit("setChoiceBoitePlanning", null);
        }
      }
    },
  },
};
</script>

<style scoped>
button.btnGroupe {
  margin-bottom: 1px;
}
div.choixlieu {
  display: flex;
  margin-top: 2em;
  height: 40%;
  width: 91%;
  border: 1px solid black;
}
div.choiceBoite label {
  margin-right: 5%;
  margin-bottom: 1em;
}
div.boite label {
  margin-right: 5%;
}
div.container {
  display: flex;
}
div.form {
  border-right: 1px solid black;
  width: 50%;
  margin-right: 1em;
}

div.planningBoite {
  width: 70%;
}

.field {
  position: relative;
}

.labelForm {
  position: absolute;
  pointer-events: none;
  left: 1.25rem;
  top: 7.75px;
  transition: 0.2s ease all;
  font-size: 15px;
}

.field > input:focus ~ label,
input:not(:placeholder-shown) ~ label,
.field > select:focus ~ label,
select:not(:placeholder-shown) ~ label {
  top: -15px;
  font-size: 12px;
}

.mat-dialog-container {
  padding: 0px !important;
}

div.groupe {
  width: 20%;
  height: 100%;
  overflow: scroll;
}

div.table {
  border-left: 1px solid;
  width: 80%;
  height: 100%;
  overflow: scroll;
}
</style>
