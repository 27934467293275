<template>
  <div id="conteneur">
    <div id="listeEleve" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreDossier">Dossier</h1>
      </header>

      <div id="filter-agence">
        <select
          v-if="this.$store.state.user.role.label === 'admin'"
          v-model="agenceSelected"
          id="agence"
          name="agence"
          class="form-select"
          placeholder="Agence"
        >
          <option value="all">Toutes</option>
          <option value="null">Aucune</option>

          <option
            v-for="(item, index) in agences"
            :value="item.id"
            :key="index"
          >
            {{ item.nom }}
          </option>
        </select>
        <div class="flex justify-self-flex-end">
          <span class="p-input-icon-left">
            <input
              class="ms-5"
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </span>
          <i class="pi pi-search me-2 ms-2 mt-3" />
        </div>
      </div>

      <DataTable
        :value="elevesDossier"
        :rows="10"
        v-model:filters="filters"
        v-model:selection="eleveSelected"
        selectionMode="single"
        filterDisplay="menu"
        :globalFilterFields="[
          'datep',
          'nom',
          'prenom',
          'dossier.dateNaissance',
          'dossier.statutDossier',
        ]"
        :paginator="true"
        responsiveLayout="scroll"
        dataKey="id"
      >
        <Column field="datep" header="Date" :sortable="true"></Column>

        <Column field="nom" header="Nom" :sortable="true"></Column>

        <Column field="prenom" header="Prenom" :sortable="true"></Column>

        <Column field="dossier.dateNaissance" header="Age" :sortable="true">
          <template #body="{ data }">
            <template v-if="data.dossier.dateNaissance.date">
              {{
                new Date().getUTCFullYear() -
                new Date(data.dossier.dateNaissance.date).getUTCFullYear()
              }}
            </template>
            <template v-else>
              {{
                new Date().getUTCFullYear() -
                new Date(data.dossier.dateNaissance).getUTCFullYear()
              }}
            </template>
          </template>
        </Column>

        <Column field="dossier.statutDossier" header="Statut" :sortable="true">
          <template #body="{ data }">
            {{ calculStatutDossier(data) }}
          </template>
        </Column>
      </DataTable>
    </div>

    <div id="detailsEleve">
      <template v-if="eleveSelected === null">
        <div id="right">
          <div id="styleTitreChoix">
            <p id="titreChoix">Sélectionnez un élève</p>
          </div>
        </div>

      </template>

      <template v-else>
        <div id="detailsEleveMenuHead">
          <div><button @click="selectForm()">Formulaire</button></div>
          <div>{{ eleveSelected.nom }} {{ eleveSelected.prenom }}</div>
          <div id="groupSaisiNeph" class="input-group">
            <template v-if="!isWorkingSaisi">
              <input
                type="checkbox"
                id="btnCheckSaisi"
                autocomplete="off"
                v-model="eleveSelected.dossier.ants"
                @change="clickSaisi()"
              />
            </template>
            <template v-else>
              <input
                type="checkbox"
                id="btnCheckSaisi"
                autocomplete="off"
                v-model="eleveSelected.dossier.ants"
                disabled
              />
            </template>
            <label for="btnCheckSaisi">Saisi</label>
            <input
              type="text"
              class="form-control"
              placeholder="NEPH"
              v-model="eleveSelected.dossier.numNeph"
            />
            <template v-if="!isWorkingNeph">
              <button
                type="button"
                class="input-group-text"
                id="btnSendNeph"
                @click="openNephModal()"
              >
                <i class="fa fa-arrow-right"></i>
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="input-group-text"
                id="btnSendNeph"
                disabled
              >
                <i class="fa fa-spinner fa-spin"></i>
              </button>
            </template>
          </div>
        </div>
        <div id="detailsEleveMenuPiece" :class="{ isHide: formSelected }">
          <div
            v-for="item in eleveSelected.dossier.pieceJustificatives"
            :key="item.id"
          >
            <button
              @click="selectPiece(item)"
              class="btn"
              :class="{
                pieceSelectedClass: pieceSelected === item,
                pieceATaiterClass: item.aTraiter,
                pieceValideClass: item.etat,
              }"
            >
              {{ item.typePieceJustificative.label }}
            </button>
          </div>
        </div>
        <template v-if="formSelected">
          <div id="detailsEleveForm">
            <Formulaire
              :userData="eleveSelected"
              :showValidation="showValidation"
              :isWorking="isWorking"
              @update="update($event)"
              @updateCheckAll="updateCheckAll($event)"
              @ordreVueMobile="ordreVueMobile($event)"
              @saveDossier="saveDossier()"
            ></Formulaire>
          </div>
        </template>
        <template v-else-if="pieceSelected === null">
          <div id="detailsEleveColor">
            <p id="detailsEleveNonSelectedMsg">Sélectionnez une pièce</p>
          </div>
        </template>
        <template v-else-if="pieceSelected.fichier === null">
          <div id="detailsEleveColor">
            <Loader />
          </div>
        </template>
        <template v-else>
          <div id="detailsElevePiece">
            <div class="card border-dark mb-3">
              <div class="card-header card-header-piece">
                HISTORIQUE DES ENVOIS À L'AGENCE
              </div>
              <div class="card-body text-dark" style="text-align: left">
                <div v-if="fileList.length !== 0">
                  <p
                    @click="unselectFichier()"
                    class="card-text pointer"
                    :class="{
                      fichierSelectedClass: fichierSelected === null,
                    }"
                  >
                    {{ dateNow() }} - Nouveau
                  </p>
                </div>
                <div
                  v-for="fichier in pieceSelected.fichier.slice().reverse()"
                  :key="fichier.date"
                  class="ligne_envoi"
                >
                  <p
                    @click="selectFichier(fichier)"
                    class="card-text pointer"
                    :class="{
                      fichierSelectedClass: fichierSelected === fichier,
                    }"
                  >
                    {{
                      fichier.date
                        .replace(/-/g, "/")
                        .replace(/ .{0,}/g, "")
                        .replace(/^....[/]..[/]/g, "") +
                      "/" +
                      fichier.date
                        .replace(/-/g, "/")
                        .replace(/ .{0,}/g, "")
                        .replace(/^....[/]/g, "")
                        .replace(/[/]..$/g, "") +
                      "/" +
                      fichier.date
                        .replace(/-/g, "/")
                        .replace(/ .{0,}/g, "")
                        .replace(/[/]..[/]..$/g, "")
                    }}
                    -
                    <template v-if="fichier.etat === null">
                      À traiter
                    </template>
                    <template v-else-if="fichier.etat === false">
                      Refusé
                    </template>
                    <template v-else> Accepté </template>
                  </p>
                  <template v-if="isWorkingDel !== fichier">
                    <div class="cross" @click="delAllFile(fichier)">
                      <i class="fa fa-times"></i>
                    </div>
                  </template>
                  <template v-else>
                    <div class="cross">
                      <i class="fa fa-spinner fa-spin"></i>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="card border-dark mb-3">
              <div class="card-header card-header-piece">
                {{this.labelBoutonDossier}} - VISUALISATION DES FICHIERS ENVOYÉS
              </div>
              <div class="card-body text-dark" style="text-align: left">
                <div v-if="fileList.length !== 0 && fichierSelected === null">
                  <div v-for="file in fileList" :key="file.file_name">
                    <a
                      :href="file.file_data"
                      :download="file.file_name"
                      target="_blank"
                      class="card-text"
                      >{{ file.file_name }}</a
                    >
                    <div class="cross" @click="delFile(file)">
                      <i class="fa fa-times"></i>
                    </div>
                  </div>
                </div>
                <div v-else-if="fichierSelected !== null">
                  <div
                    v-for="file in fichierSelected.fichier"
                    :key="file.file_name"
                  >
                    <a
                      :href="file.file_data"
                      :download="file.file_name"
                      target="_blank"
                      class="card-text"
                      >{{ file.file_name }}</a
                    >
                  </div>
                </div>
              </div>
              <template v-if="fileList.length !== 0">
                <template v-if="!savePieceClick">
                  <button
                    id="btnSavePiece"
                    type="button"
                    class="btn btn-primary"
                    @click="savePiece()"
                  >
                    Envoyer
                  </button>
                </template>
                <template v-else>
                  <button
                    id="btnSavePiece"
                    type="button"
                    class="btn btn-primary"
                    disabled
                  >
                    <i class="fa fa-spinner fa-spin"></i> Envoyer
                  </button>
                </template>
              </template>
            </div>
            <div id="btnGestionFileAdmin">
              <div>
                <template v-if="fileList.length === 0">
                  <template v-if="!isWorkingValider">
                    <input
                      type="checkbox"
                      id="valide"
                      v-model="pieceSelected.etat"
                      @change="valider($event)"
                    />
                  </template>
                  <template v-else>
                    <input
                      type="checkbox"
                      id="valide"
                      v-model="pieceSelected.etat"
                      disabled
                    />
                  </template>
                  <label for="valide">Validé</label>
                </template>
              </div>
              <div>
                <template
                  v-if="
                    fichierSelected !== null && fichierSelected.etat === null
                  "
                >
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="traiter(true)"
                  >
                    Accepter
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="traiter(false)"
                  >
                    Refuser
                  </button>
                </template>
              </div>
              <div>
                <label
                  for="file_input"
                  class="btn btn-primary"
                  :class="{
                    disabled:
                      this.pieceSelected.fichier.length !== 0 &&
                      this.pieceSelected.fichier.slice().reverse()[0].etat ==
                        null,
                  }"
                >
                  Ajouter
                </label>
                <template
                  v-if="
                    this.pieceSelected.fichier.length === 0 ||
                    this.pieceSelected.fichier.slice().reverse()[0].etat != null
                  "
                >
                  <input
                    type="file"
                    id="file_input"
                    name="file_input"
                    @change="addFile()"
                    multiple
                  />
                </template>
              </div>
            </div>
            <div id="commentaireConteneur">
              <textarea
                v-model="pieceSelected.commentaire"
                id="commentaire"
                class="form-control"
                placeholder="Commentaire..."
              />
              <template v-if="!saveCommentaireClick">
                <button
                  id="confirmerCommentaire"
                  type="button"
                  class="btn btn-primary"
                  @click="confirmerCommentaire()"
                >
                  Confirmer le commentaire
                </button>
              </template>
              <template v-else>
                <button
                  id="confirmerCommentaire"
                  type="button"
                  class="btn btn-primary"
                  disabled
                >
                  <i class="fa fa-spinner fa-spin"></i> Confirmer le commentaire
                </button>
              </template>
            </div>
          </div>
        </template>
      </template>
    </div>
    <!-- The Modal -->
    <div id="myModalFormulaire" class="modal" v-if="modalSelected">
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span class="close" @click="closeModal()">&times;</span>
          <h2>Valider la pièce</h2>
        </div>
        <div class="modal-body">
          <p>Devez vous valider la pièce</p>
          <button
            type="button"
            class="btn btn-success"
            @click="validerPiece()"
            v-if="!isWorkingValider"
          >
            OUI
          </button>
          <button type="button" class="btn btn-success" v-else>
            <i class="fa fa-spinner fa-spin"></i> En cours de validation
          </button>
        </div>
      </div>
    </div>

    <!-- The Modal -->
    <div id="myModalNeph" class="modal" v-if="modalNephSelected">
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span class="close" @click="closeNephModal()">&times;</span>
          <h2>Enregistrement du numéro NEPH</h2>
        </div>
        <div class="modal-body">
          <p>
            ATTENTION, saisir un caractère ici entraîne la suppression des
            pièces du dossier
          </p>
          <button
            type="button"
            class="btn btn-success"
            @click="clickNeph()"
            v-if="!isWorkingNeph"
          >
            Continuer
          </button>
          <button type="button" class="btn btn-success" v-else>
            <i class="fa fa-spinner fa-spin"></i> En cours
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";
import Loader from "@/components/Loader.vue";
import Formulaire from "@/components/dossier/Formulaire.vue";
import DropdownNav from "@/components/DropdownNav.vue";

export default {
  name: "AdministrationDossier",
  components: {
    Loader,
    Formulaire,
    DropdownNav,
  },
  data() {
    return {
      agenceSelected: "all",
      eleveSelected: null,
      pieceSelected: null,
      labelBoutonDossier: null,
      fichierSelected: null,
      formSelected: false,
      fileList: [],
      savePieceClick: false,
      isWorking: false,
      isWorkingSaisi: false,
      isWorkingNeph: false,
      isWorkingValider: false,
      isWorkingDel: null,
      showValidation: false,
      checkAll: false,
      modalSelected: false,
      modalNephSelected: false,
      saveCommentaireClick: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  watch: {
    eleveSelected(value) {
      this.selectEleve(value);
    },
  },
  mounted() {
    this.getElevesDossier();
    this.getTypePieceJustificative();
  },
  methods: {
    ...mapActions(["getElevesDossier", "getTypePieceJustificative"]),

    openModal() {
      // Ouvre le modal de validation des pièces
      this.modalSelected = true;
    },

    closeModal() {
      // Ferme le modal de validation des pièces
      this.modalSelected = false;
    },

    openNephModal() {
      // Ouvre le modal d'enregistrement du numéro NEPH
      this.modalNephSelected = true;
    },

    closeNephModal() {
      // Ferme le modal d'enregistrement du numéro NEPH
      this.modalNephSelected = false;
    },

    validerPiece() {
      // Indique qu'une pièce est en cours de validation
      this.isWorkingValider = true;
      // Enregistre la modification
      window.api
        .patch(
          "/piece_justificatives/" + this.pieceSelected.id,
          {
            etat: true,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Valide la pièce
          this.pieceSelected.etat = true;
          // Met à jour la liste des éléves
          this.majElevesDossier();
          // Ferme le modal de validation des pièces
          this.closeModal();
          // Indique qu'une pièce n'est plus en cours de validation
          this.isWorkingValider = false;
        })
        .catch((error) => {
          // Indique qu'une pièce n'est plus en cours de validation
          this.isWorkingValider = false;
          console.log(error);
        });
    },

    confirmerCommentaire() {
      // Indique qu'un commentaire est en cours de d'enregistrement
      this.saveCommentaireClick = true;
      // Enregistre le commentaire
      window.api
        .patch(
          "/piece_justificatives/" + this.pieceSelected.id,
          {
            commentaire: this.pieceSelected.commentaire,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Met à jour la liste des éléves
          this.majElevesDossier();
          // Indique qu'un commentaire n'est plus en cours de d'enregistrement
          this.saveCommentaireClick = false;
        })
        .catch((error) => {
          // Indique qu'un commentaire n'est plus en cours de d'enregistrement
          this.saveCommentaireClick = false;
          console.log(error);
        });
    },

    selectForm() {
      // Si le formulaire est affiché
      if (this.formSelected) {
        // Alors ont arrète l'affichage
        this.formSelected = false;
      } else {
        // Sinon on l'affiche
        this.formSelected = true;
      }
    },

    dateNow() {
      // Calcul la date actuelle et la formate
      // Initialisation vide
      let dateToReturn = "";
      // Si le jour est inferieur ou égale à 9
      if (new Date().getDate() <= 9) {
        // Alors ajout d'un zéro précédent le jour
        dateToReturn += "0";
      }
      // Ajout du jour et d'un slash entre le jour et le mois
      dateToReturn += new Date().getDate() + "/";
      // Les mois vont de 0 à 11
      // Si le mois est le 9ème ou moins
      if (new Date().getMonth() < 9) {
        // Alors ajout d'un zéro précédent le mois
        dateToReturn += "0";
      }
      // Ajout du mois+1, d'un slash entre le mois et l'année et de l'année
      dateToReturn +=
        new Date().getMonth() + 1 + "/" + new Date().getFullYear();

      // Retourne la date actuelle formatée
      return dateToReturn;
    },

    selectEleve(eleve) {
      // Déséléctionne la pièce
      this.unselectPiece();
      // Séléctionne l'élève
      this.eleveSelected = eleve;
      // Si l'élève est séléctionné
      if (this.eleveSelected !== null) {
        // Si la date de naissance doit être reformaté
        if (this.eleveSelected.dossier.dateNaissance.date != undefined) {
          // Reformatage de la date
          this.eleveSelected.dossier.dateNaissance =
            this.eleveSelected.dossier.dateNaissance.date;
        }
      }
    },

    unselectEleve() {
      // Déséléctionne la pièce
      this.unselectPiece();
      // Déséléctionne l'élève
      this.eleveSelected = null;
    },

    selectPiece(piece) {

       
      
      // Vide la fileList
      this.fileList = [];
      // Déséléctionne l'envoi
      this.unselectFichier();
      // Séléctionne la pièce
      this.pieceSelected = piece;
      // Si les envois de la pièce ne sont pas chargés
      if (this.pieceSelected.fichier === null) {
        // Charge la pièce séléctionnée
        window.api
          .get("/piece_justificatives/" + this.pieceSelected.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            // Place les données dans la liste des envois de la pièce séléctionnée
            this.pieceSelected.fichier = response.data.fichier;
            // Met à jour la liste des éléves
            this.majElevesDossier();


            
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.labelBoutonDossier = this.pieceSelected.typePieceJustificative.label
      // console.log(this.labelBoutonDossier);
    },

    unselectPiece() {
      // Vide la fileList
      this.fileList = [];
      // Déséléctionne l'envoi
      this.unselectFichier();
      // Déséléctionne la pièce
      this.pieceSelected = null;
    },

    selectFichier(fichier) {
      // Séléctionne l'envoi
      this.fichierSelected = fichier;
    },

    unselectFichier() {
      // Déséléctionne l'envoi
      this.fichierSelected = null;
    },

    addFile() {
      // Ajout de fichier à la fileList
      // Récupère l'élément ayant l'id "file_input"
      let input = document.getElementById("file_input");
      // Boucle les fichiers présent dans l'input récupéré précédemment
      for (let i = 0; i < input.files.length; i++) {
        // Initialise le FileReader
        const reader = new FileReader();
        // Execute quand le reader est load et met un fichier dans fileList
        reader.onloadend = () => {
          this.fileList.push({
            file_name: input.files[i].name,
            file_data: reader.result,
          });
        };
        reader.readAsDataURL(input.files[i]);
      }
      // Désélectionne les envoies
      this.unselectFichier();
    },

    delFile(item) {
      // Retire de la fileList le fichier en paramètre
      this.fileList = this.fileList.filter((el) => el !== item);
    },

    delAllFile(file) {
      // Si la deletion des fichiers de l'envoi n'est pas en cours
      if (this.isWorkingDel === null) {
        // Indique que la deletion des fichiers de l'envoi est en cours
        this.isWorkingDel = file;
        let tmpPieceSelected = this.pieceSelected;
        // Identifie l'envoi en fonction de la date
        let idFichierSelected = tmpPieceSelected.fichier.findIndex(
          (el) => el.date === file.date
        );
        // Supprime les fichiers
        tmpPieceSelected.fichier[idFichierSelected].fichier = [];
        // Met à jour la pièce séléctionnée
        window.api
          .patch(
            "/piece_justificatives/" + this.pieceSelected.id,
            {
              fichier: tmpPieceSelected.fichier,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            // Met à jour la liste des envois
            this.pieceSelected.fichier = tmpPieceSelected.fichier;
            // Déséléctionne l'envoi
            this.unselectFichier();
            // Met à jour la liste des éléves
            this.majElevesDossier();
            // Indique que la deletion des fichiers de l'envoi n'est plus en cours
            this.isWorkingDel = null;
          })
          .catch((error) => {
            // Indique que la deletion des fichiers de l'envoi n'est plus en cours
            this.isWorkingDel = null;
            console.log(error);
          });
      }
    },

    savePiece() {
      // Indique qu'une sauvegarde de pièce est en cours
      this.savePieceClick = true;
      let tmpPieceSelectedFichier = this.pieceSelected.fichier;
      // Ajoute l'envoi à la liste
      tmpPieceSelectedFichier.push({
        date: new Date().toISOString().slice(0, 19).replace("T", " "),
        fichier: this.fileList,
        etat: null,
      });
      // Enregistre la pièce
      window.api
        .patch(
          "/piece_justificatives/" + this.pieceSelected.id,
          {
            commentaire: this.pieceSelected.commentaire,
            fichier: tmpPieceSelectedFichier,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Indique qu'il y a une nouvelle pièce à traiter
          this.pieceSelected.aTraiter++;
          // Modifi les données local
          this.pieceSelected.fichier = tmpPieceSelectedFichier;
          // Vide la fileList
          this.fileList = [];
          // Déséléctionne l'envoi
          this.unselectFichier();
          // Met à jour la liste des éléves
          this.majElevesDossier();
          // Indique qu'une sauvegarde de pièce n'est plus en cours
          this.savePieceClick = false;
        })
        .catch((error) => {
          // Indique qu'une sauvegarde de pièce n'est plus en cours
          this.savePieceClick = false;
          console.log(error);
        });
    },

    valider(e) {
      // Indique qu'un envoi est en cours de traitement
      this.isWorkingValider = true;
      // Met à jour la pièce
      window.api
        .patch(
          "/piece_justificatives/" + this.pieceSelected.id,
          {
            etat: e.target.checked,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Modifie l'état sur les données local
          this.pieceSelected.etat = e.target.checked;
          // Met à jour la liste des éléves
          this.majElevesDossier();
          // Indique qu'un envoi n'est plus en cours de traitement
          this.isWorkingValider = false;
        })
        .catch((error) => {
          // Indique qu'un envoi n'est plus en cours de traitement
          this.isWorkingValider = false;
          console.log(error);
        });
    },

    traiter(etat) {
      let tmpPieceSelectedFichier = this.pieceSelected.fichier;
      tmpPieceSelectedFichier[this.pieceSelected.fichier.length - 1].etat =
        etat;
      // Ouvre le modal de validation si l'envoi est accépté
      this.modalSelected = etat;
      // Mise à jour de la pièce
      window.api
        .patch(
          "/piece_justificatives/" + this.pieceSelected.id,
          {
            commentaire: this.pieceSelected.commentaire,
            fichier: tmpPieceSelectedFichier,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Indique que la pièce n'est plus à traiter
          this.pieceSelected.aTraiter--;
          // Modifi les données local
          this.pieceSelected.fichier = tmpPieceSelectedFichier;
          // Si l'envoi est accépté
          if (etat === false) {
            // Envoi un mail
            window.api
              .post(
                "/sendMail",
                {
                  type: "dossier refus",
                  piece: this.pieceSelected.typePieceJustificative.label,
                  email: this.eleveSelected.email,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          }
          // Met à jour la liste des éléves
          this.majElevesDossier();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    calculStatutDossier(user) {
      // Calcul du statut du dossier
      // Valeur initial = "Incomplet"
      let statut = "Incomplet";
      // Si l'élève a des pièces justificatives
      if (user.dossier.pieceJustificatives.length !== 0) {
        // Démarre les compteurs des états des pièces
        let countNbValidees = 0;
        let countNbRefusees = 0;
        let countNbSansEtat = 0;
        // Boucle les pièces justificatives
        for (let i = 0; i < user.dossier.pieceJustificatives.length; i++) {
          // Si la pièce n'est pas validée
          if (user.dossier.pieceJustificatives[i].etat !== true) {
            // Si les envois sont chargés
            if (user.dossier.pieceJustificatives[i].fichier !== null) {
              // Si il y a plus d'un envoi
              if (user.dossier.pieceJustificatives[i].fichier.length) {
                // Si le dernier envoi est à traiter
                if (
                  user.dossier.pieceJustificatives[i].fichier
                    .slice()
                    .reverse()[0].etat === null
                ) {
                  // Alors +1 pièce à traiter
                  countNbSansEtat++;
                } else {
                  // Alors +1 pièce refusé (car refusé reste "Incomplet")
                  countNbRefusees++;
                }
              }
            } else {
              // Si la donnée précalculé indique que la pièce est à traiter
              if (user.dossier.pieceJustificatives[i].aTraiter > 0) {
                // Passage du statut en "À traiter"
                statut = "À traiter";
              }
            }
          } else {
            // Sinon la pièce est validé
            // Donc +1 pièce validée
            countNbValidees++;
          }
        }
        // Si la somme des compteurs === nombre des pièces justificatives
        if (
          countNbValidees + countNbRefusees + countNbSansEtat ===
          user.dossier.pieceJustificatives.length
        ) {
          // Si le compteur des pièces validées === nombre des pièces justificatives
          if (countNbValidees === user.dossier.pieceJustificatives.length) {
            // Passage du statut en "Validées"
            statut = "Validées";
          }
        }
        // Si il y a au moins une pièce à traiter
        if (countNbSansEtat) {
          // Passage du statut en "À traiter"
          statut = "À traiter";
        }
      }
      // Si le dossier est saisi sur ANTS
      if (user.dossier.ants) {
        // Passage de statut en "Saisi"
        statut = "Saisi";
      }
      // Si il y a un numéro NEPH
      if (user.dossier.numNeph.length > 0) {
        // Passage de statut en "Enregistrer"
        statut = "Enregistrer";
      }
      // Modif du statut du dossier dans les données local
      user.dossier.statutDossier = statut;
      return statut;
    },

    saveDossier() {
      // Indique que le dossier est en cours d'enregistrement
      this.isWorking = true;
      if (this.eleveSelected.dossier.motifAlcoolStup === null) {
        this.eleveSelected.dossier.motifAlcoolStup = false;
      }
      let tmpFormDossier = this.eleveSelected.dossier;
      // Comparaison "true" ou true = true pour être sur d'envoyer correctement le true
      // Sinon, la comparaison = false
      tmpFormDossier.nationaliteFR =
        tmpFormDossier.nationaliteFR === "true" ||
        tmpFormDossier.nationaliteFR === true;
      tmpFormDossier.parlerFRCouramment =
        tmpFormDossier.parlerFRCouramment === "true" ||
        tmpFormDossier.parlerFRCouramment === true;
      tmpFormDossier.lunettes =
        tmpFormDossier.lunettes === "true" || tmpFormDossier.lunettes === true;
      tmpFormDossier.vehiculeAmenage =
        tmpFormDossier.vehiculeAmenage === "true" ||
        tmpFormDossier.vehiculeAmenage === true;
      tmpFormDossier.prevoitAac =
        tmpFormDossier.prevoitAac === "true" ||
        tmpFormDossier.prevoitAac === true;
      tmpFormDossier.faitSuite =
        tmpFormDossier.faitSuite === "true" ||
        tmpFormDossier.faitSuite === true;
      tmpFormDossier.motifAlcoolStup =
        tmpFormDossier.motifAlcoolStup === "true" ||
        tmpFormDossier.motifAlcoolStup === true;
      if (tmpFormDossier.lycee === "null") {
        tmpFormDossier.lycee = null;
      }
      // Modifi le dossier en base de donnée
      window.api
        .patch(
          "/dossiers/" + this.eleveSelected.dossier.id,
          {
            adresse: tmpFormDossier.adresse,
            ville: tmpFormDossier.ville,
            codePostal: tmpFormDossier.codePostal,
            paysNaissance: tmpFormDossier.paysNaissance,
            depNaissance: tmpFormDossier.depNaissance,
            villeNaissance: tmpFormDossier.villeNaissance,
            lunettes: tmpFormDossier.lunettes,
            nomUsage: tmpFormDossier.nomUsage,
            autresPrenom: tmpFormDossier.autresPrenom,
            numNeph: tmpFormDossier.numNeph,
            dateNaissance: tmpFormDossier.dateNaissance,
            nationaliteFR: tmpFormDossier.nationaliteFR,
            parlerFRCouramment: tmpFormDossier.parlerFRCouramment,
            vehiculeAmenage: tmpFormDossier.vehiculeAmenage,
            prevoitAac: tmpFormDossier.prevoitAac,
            permis: tmpFormDossier.permis,
            ants: tmpFormDossier.ants,
            social: tmpFormDossier.social,
            faitSuite: tmpFormDossier.faitSuite,
            motifAlcoolStup: tmpFormDossier.motifAlcoolStup,
            lycee: tmpFormDossier.lycee,
            telParent: tmpFormDossier.telParent,
            mailParent: tmpFormDossier.mailParent,
            metier: tmpFormDossier.metier,
            autreLycee: tmpFormDossier.autreLycee,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then((response) => {
          this.eleveSelected.dossier = response.data;
          this.eleveSelected.dossier.pieceJustificatives =
            tmpFormDossier.pieceJustificatives;
          this.eleveSelected.dossier.statutDossier =
            tmpFormDossier.statutDossier;
          // Initialisation de typePieces avec les pièces permanente
          let typePieces = this.$store.state.typePieceJustificative.filter(
            (el) => el.useCase === "dossier"
          );
          // Calcul de l'age
          let age =
            new Date().getUTCFullYear() -
            new Date(this.eleveSelected.dossier.dateNaissance).getUTCFullYear();

          // Suivant des conditions, différents type de pièce sont ajoutés à typePieces

          // Si l'élève a entre 17 ans inclus et 25 ans exclu
          if (age >= 17 && age < 25) {
            Array.prototype.push.apply(
              typePieces,
              this.$store.state.typePieceJustificative.filter(
                (el) => el.useCase === "age"
              )
            );
          }
          // Si la demande fait suite à une annulation/invalidation de permis
          if (this.eleveSelected.dossier.faitSuite) {
            Array.prototype.push.apply(
              typePieces,
              this.$store.state.typePieceJustificative.filter(
                (el) => el.useCase === "faitSuite"
              )
            );
            // Si l'annulation/invalidation est lié à l'alcool ou la drogue
            if (this.eleveSelected.dossier.motifAlcoolStup) {
              Array.prototype.push.apply(
                typePieces,
                this.$store.state.typePieceJustificative.filter(
                  (el) => el.useCase === "faitSuite/oui"
                )
              );
            } else {
              Array.prototype.push.apply(
                typePieces,
                this.$store.state.typePieceJustificative.filter(
                  (el) => el.useCase === "faitSuite/non"
                )
              );
            }
          }
          // Mise à jour des pièces relatives à l'utilisateur
          window.api
            .post(
              "/dossier/piece",
              {
                dossier: this.eleveSelected.dossier,
                typePieces: typePieces,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              // Modif les données en local
              this.eleveSelected.dossier = response.data;
              // Met à jour la liste des éléves
              this.majElevesDossier();
              // Lance la sauvegarde des informations de l'utilisateur
              this.saveUser();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          // Déséléctionne le formulaire
          this.formSelected = false;
          // Indique que le dossier n'est plus en cours d'enregistrement
          this.isWorking = false;
          console.log(error);
        });
    },

    saveUser() {
      // Modifi les informations de l'utilisateur en base de données
      window.api
        .patch(
          "/utilisateur/" + this.eleveSelected.id,
          {
            nom: this.eleveSelected.nom,
            prenom: this.eleveSelected.prenom,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          // Déséléctionne le formulaire
          this.formSelected = false;
          // Met à jour la liste des éléves
          this.majElevesDossier();
          // Indique que le dossier n'est plus en cours d'enregistrement
          this.isWorking = false;
        })
        .catch((error) => {
          // Déséléctionne le formulaire
          this.formSelected = false;
          // Indique que le dossier n'est plus en cours d'enregistrement
          this.isWorking = false;
          console.log(error);
        });
    },

    suivantEtape() {
      // Passe à l'étape suivante
      if (this.etape < 4) {
        this.etape++;
      }
    },

    retourEtape() {
      // Passe à l'étape précédente
      if (this.etape > 1) {
        this.etape--;
      }
    },

    update(userData) {
      // update des infos du dossier depuis le composant Formulaire
      this.eleveSelected = userData;
    },

    updateCheckAll(value) {
      // update du checkAll depuis le composant Formulaire
      this.checkAll = value;
    },

    clickSaisi() {
      // Indique l'enregistrement de la saisi du dossier sur ANTS
      this.isWorkingSaisi = true;
      // Mise à jour du dossier
      window.api
        .patch(
          "/dossiers/" + this.eleveSelected.dossier.id,
          {
            ants: this.eleveSelected.dossier.ants,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Met à jour la liste des éléves
          this.majElevesDossier();
          // Indique que l'enregistrement de la saisi du dossier sur ANTS est terminé
          this.isWorkingSaisi = false;
        })
        .catch((error) => {
          // Indique que l'enregistrement de la saisi du dossier sur ANTS est terminé
          this.isWorkingSaisi = false;
          console.log(error);
        });
    },

    clickNeph() {
      // Ouvre le modal du numéro NEPH
      this.isWorkingNeph = true;
      // Modifi le numéro en base de données
      window.api
        .patch(
          "/dossiers/" + this.eleveSelected.dossier.id,
          {
            numNeph: this.eleveSelected.dossier.numNeph,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          // Envoi un mail
          window.api
            .post(
              "/sendMail",
              {
                type: "dossier valide",
                email: this.eleveSelected.email,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
          // Lance la deletion des pièces en base de données
          window.api
            .post(
              "/dossier/deletePiece",
              {
                dossier: this.eleveSelected.dossier,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              // Met à jour la liste des éléves
              this.majElevesDossier(true);
              // Indique que l'enregistrement du numéro NEPH est terminé
              this.isWorkingNeph = false;
              // Ferme le modal du numéro NEPH
              this.modalNephSelected = false;
            });
        })
        .catch((error) => {
          // Indique que l'enregistrement du numéro NEPH est terminé
          this.isWorkingNeph = false;
          console.log(error);
        });
    },

    majElevesDossier(clickNephParam = false) {
      // Met à jour la liste des élèves

      // Identifie la position de l'élève séléctionné
      let idElevesDossierSelected =
        this.$store.state.elevesDossier.utilisateurs.findIndex(
          (el) => el.id === this.eleveSelected.id
        );
      // Met à jour l'élève dans la liste
      this.$store.state.elevesDossier.utilisateurs[idElevesDossierSelected] =
        this.eleveSelected;
      // Si il y a une pièce séléctionnée
      if (this.pieceSelected != null) {
        // Identifie la position de la pièce séléctionnés
        let idElevesDossierPieceSelected =
          this.$store.state.elevesDossier.utilisateurs[
            idElevesDossierSelected
          ].dossier.pieceJustificatives.findIndex(
            (el) => el.id === this.pieceSelected.id
          );
        // Met à jour la pièce dans la liste
        this.$store.state.elevesDossier.utilisateurs[
          idElevesDossierSelected
        ].dossier.pieceJustificatives[idElevesDossierPieceSelected] =
          this.pieceSelected;
      }
      // Si le paramètre est true
      if (clickNephParam) {
        // Déséléctionne l'élève
        this.unselectEleve();
        // Retire l'élève de la liste
        this.$store.state.elevesDossier.utilisateurs.splice(
          idElevesDossierSelected,
          1
        );
      }
    },
  },
  computed: {
    elevesDossierLoad() {
      return this.$store.state.elevesDossier !== undefined;
    },
    elevesDossier() {
      // Si la liste des élèves est chargé
      if (this.elevesDossierLoad) {
        // Si la séléction d'agence est "Toutes"
        if (this.agenceSelected === "all") {
          // Retourne toute la liste
          return this.$store.state.elevesDossier.utilisateurs;
        } else if (this.agenceSelected === "null") {
          // Sinon, si la séléction d'agence est "Toutes"
          // Retourne seulement les utilisateurs non rattachés
          return this.$store.state.elevesDossier.utilisateurs.filter(
            (el) => el.agence === null
          );
        } else {
          // Sinon
          // Retourne les utilisateurs rattachés à l'agence séléctionnée
          return this.$store.state.elevesDossier.utilisateurs.filter(
            (el) => el.agence === this.agenceSelected
          );
        }
      }
      return [];
    },
    agences() {
      if (this.elevesDossierLoad) {
        return this.$store.state.elevesDossier.agences;
      }
      return [];
    },
  },
};
</script>

<style scoped>

#titreChoix {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}
#styleTitreChoix {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
  align-self: center;
}

#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  justify-content: center !important;
  min-height: 100vh;
  display: flex;
}

#agence {
  width: fit-content;
  align-self: flex-end;
}

#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
}

#iconHome {
  cursor: pointer;
}

#titreDossier {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}

#listeEleve {
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#detailsEleve {
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#groupSaisiNeph {
  width: auto;
}

#btnSendNeph {
  border: 1px solid black !important;
  border-radius: 0 10px 10px 0 !important;
  box-shadow: none !important;
}

#detailsEleveMenuHead,
#detailsEleveMenuPiece {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
}

#detailsEleveMenuHead {
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;
}

#detailsEleveMenuPiece {
  flex-wrap: wrap;
  flex: 3;
}

#detailsEleveMenuHead > div,
#detailsEleveMenuPiece > div {
  align-self: center;
  margin: 0.2rem;
}

#detailsEleveMenuHead button,
#detailsEleveMenuPiece button {
  background: white;
  padding: 0.5rem;
  border: none;
  border-radius: 10px;
  box-shadow: 3px 3px 5px #0005;
}

.pieceSelectedClass {
  background-color: #2e9ea33b !important;
}

.pieceATaiterClass {
  color: #fff;
  background-color: #ff8c00 !important;
}

.pieceValideClass {
  color: #fff;
  background-color: #198754 !important;
}

.ligne_envoi {
  display: flex;
}

#detailsElevePiece {
  margin: auto;
  width: 100%;
  flex: 1;
}

#detailsElevePiece > div {
  text-align: left;
  margin: 1rem;
}

.card-header-piece {
  font-weight: bold;
}

#btnSavePiece {
  width: fit-content;
  align-self: flex-end;
  margin: 0 0.5rem 0.5rem 0;
}

#btnGestionFileAdmin {
  display: flex;
  justify-content: space-between;
}

#btnGestionFileAdmin > div {
  width: fit-content;
}

.cross {
  display: inline;
  cursor: pointer;
  margin-left: 1rem;
}

#file_input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fichierSelectedClass {
  font-weight: bold;
}

#btnCheckSaisi ~ label {
  align-self: center;
  margin-right: 0.5rem;
}

input[type="checkbox"] {
  margin-right: 0.5rem;
}

#commentaire {
  height: 10rem;
}

#filter-agence {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 2%;
}

#detailsEleveColor {
  margin: auto;
  width: 100%;
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent linear-gradient(145deg, #de6fa8 0%, #fbbb5b 100%) 0%
    0% no-repeat padding-box;
}

#detailsEleveNonSelectedMsg {
  text-align: center;
  font: normal normal 600 30px/41px Galano Grotesque Alt;
  letter-spacing: 0px;
  color: #282828;
}

#detailsEleveForm {
  flex: 7;
  display: grid;
  align-items: center;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  #conteneur {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #conteneur {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
  }
}

/* formulaire */
.isNotValid {
  border-color: red;
}
.isHide {
  display: none !important;
}

.btnForm {
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #de6fa8; /* Green */
  border: none;
  color: white;
  height: 30px;
  margin: auto;
  border-radius: 10px;
}

.btnForm:hover {
  background-color: #bd135f;
}

#buttonContainer {
  display: flex;
}

.inputsize {
  width: 66%;
  margin: auto;
}

#commentaireConteneur {
  display: flex;
  flex-direction: column;
}

#confirmerCommentaire {
  width: fit-content;
  align-self: flex-end;
}

/* The Modal (background) */
.modal {
  display: block;

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  margin: auto;
  margin-top: 5em;
  background-color: #fefefe;
  width: 50%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93c2ff;
  color: white;
}

.modal-body {
  padding: 2px 16px;
  display: grid;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #ffffff;
  color: white;
}
</style>
