<template>
  <div v-if="load == 1">
    <Dialog
      v-model:visible="visibles"
      modal
      header="Moniteurs"
      :style="{ width: '90rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div class="headerModal">
        <div v-if="!showEdit">
          <template v-if="this.$store.state.typeModalMono">
            <button class="btn btn-primary rounded-pill add" @click="showEditMono">
              +
            </button>
            <template v-if="showArchive">
              <button class="btn btn-danger" @click="archiveBtn">ARCHIVES</button>
            </template>
            <template v-else>
              <button class="btn btn-primary" @click="archiveBtn">ARCHIVES</button>
            </template>
          </template>
        </div>
        <div v-else>
          <button class="btn btn-primary" @click="goBackToList">Liste des monos</button>
        </div>

      </div>
      <div class="contentModal" v-if="!showEdit">
        <DataTable
          :value="this.moniteursTable"
          responsiveLayout="scroll"
          v-model:selection="moniteurEdits"
          selectionMode="single"
          @click="onSelected"
        >
          <Column field="code" header="">
            <template #body="{ data }">
              <template v-if="!this.$store.state.typeModalMono">
                  <template v-if="data.choixPlanning == true">
                    <input
                      type="checkbox"
                      :id="data.idMono"
                      :name="data.id"
                      class="monoCheckag"
                      @click="moniteurChoice"
                      :value="data.idMono"
                      checked
                    />
                  </template>
                  <template v-else>
                    <input
                      type="checkbox"
                      :id="data.idMono"
                      :name="data.id"
                      :value="data.idMono"
                      class="monoCheckag"
                      @click="moniteurChoice"
                    />
                  </template>

              </template>
            </template>
          </Column>
          <Column field="boite" header="Boite"></Column>
          <Column field="fullname" header="Nom"></Column>
          <Column field="adresse.ville" header="Résidence"></Column>
          <Column field="telephone" header="Tél"></Column>
          <Column field="lieux" header="Lieux RDV">
            <template #body="{ data }">
              <select name="lieu" id="lieu">
                <template v-for="lieu in data.lieux" :key="lieu.id">
                  <option :value="lieu.id">{{ lieu.nom }}</option>
                </template>
              </select>
            </template>
          </Column>
          <Column field="quantity" header="Archive" v-if="this.$store.state.typeModalMono">
            <template #body="{ data }">
              <template v-if="showArchive">
                <input
                  type="checkbox"
                  :id="data.idMono"
                  :name="data.idMono"
                  @click="archiveMono"
                  checked
                />
              </template>
              <template v-else>
                <input
                  type="checkbox"
                  :id="data.idMono"
                  :name="data.idMono"
                  @click="archiveMono"
                />
              </template>
            </template>
          </Column>
        </DataTable>
      </div>
      <EditMonoModal v-else visible="true" @cancelModal="cancelModal" :moniteurEdits="moniteurEdits" @showEditMonoReset="showEditMonoReset" @getAllMoniteurs="getAllMoniteurs" />
    </Dialog>
  </div>
</template>

<script>
import EditMonoModal from "@/components/agence/planning/modals/EditMonoModal.vue";

export default {
  props: ["visible", "moniteurs", "monoChecked", "choixMonoSaves", "getLesMoniteurs"],

  components: {
    EditMonoModal,
  },

  watch: {
    visibles: function () {
      if (this.visibles == false) {
        this.$store.commit("setShowMonoPopupConduite", false);
      }
    },
  },

  data() {
    return {
      visibles: false,
      showArchive: false,
      moniteursTable: [],
      showEdit: false,
      moniteurEdits: null,
      choixSaveMono: [],
      load : 0,
    };
  },

  mounted() {
    this.visibles = this.visible;
    this.moniteursTable = this.$store.state.moniteursPlanningAgence;
    
    this.choixMonoSave();
  },

  methods: {

    cancelModal(){
      this.visibles = false;
    },

    getAllMoniteurs(){
      this.$emit("getLesMoniteurs");
    },

    goBackToList(){
      this.showEdit = false;
    },

    choixMonoSave() {
      window.api
        .get("/agence/choix/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("trete", response.data)
          if (response.data.choix.length != 0) {
            this.choixSaveMono = response.data.choix[0];
            let monoinput = document.querySelectorAll(".monoCheckag");
            monoinput.forEach((mono) => {
              this.choixSaveMono.choix.forEach((choix) => {
                if (parseInt(mono.attributes[2].value) == choix.id) {
                  mono.checked = true;
                }
              });
            });
          }
          this.load++;
          console.log("test de la liste des monos : ", this.moniteurEdits)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    moniteurChoice() {
      let checkboxMoniteur = document.querySelectorAll(".monoCheckag");
      let moniteurchoix = [];

      checkboxMoniteur.forEach((checkbox) => {
        if (checkbox.checked) {
          let res = this.$store.state.moniteursPlanningAgence.find((moniteur) => {
            return moniteur.idMono == checkbox.value;
          });
          this.$emit("moniteurChoicePlanning", res, true);
          moniteurchoix.push({ title: res.prenom, id: res.idMono });
        }else{
          let res = this.$store.state.moniteursPlanningAgence.find((moniteur) => {
            return moniteur.idMono == checkbox.value;
          });
          this.$emit("moniteurChoicePlanning", res, false);
        }
      });
      //this.calendarOptions.resources = moniteurchoix;
      if (this.$store.state.choixSaveMono.length == 0) {
        window.api
          .post(
            "/choix_dispo_eleves",
            {
              choix: moniteurchoix,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$emit("choixMonoSaves");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .put(
            "/choix_dispo_eleves/" + this.choixSaveMono.id,
            {
              choix: moniteurchoix,
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.$emit("choixMonoSaves");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    onSelected() {
      if (this.moniteurEdits != null && this.$store.state.typeModalMono == true) {
        this.showEdit = true;
      }
    },

    showEditMono() {
      this.moniteurEdits = null;
      if (this.showEdit) {
        this.showEdit = false;
      } else {
        this.showEdit = true;
      }
    },

    showEditMonoReset() {
        this.moniteurEdits = null;
        this.showEdit = false;
    },

    archiveBtn() {
      if (this.showArchive) {
        this.showArchive = false;
        this.moniteursTable = this.$store.state.moniteursPlanningAgence;
      } else {
        this.showArchive = true;
        this.moniteursTable = this.$store.state.moniteursPlanningAgenceArchive;
      }
    },

    archiveMono(e) {
      let checked = false;
      if (e.target.checked) {
        checked = true;
      }
      let moniteur = null;
      let id = e.target.id;
      if (this.showArchive) {
        moniteur = this.$store.state.moniteursPlanningAgenceArchive.find(
          (e) => e.idMono == id
        );
      } else {
        moniteur = this.$store.state.moniteursPlanningAgence.find(
          (e) => e.idMono == id
        );
      }

      if (checked) {
        window.api
          .patch(
            "/utilisateur/" + moniteur.id,
            {
              active: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            let moniteursFilter =
              this.$store.state.moniteursPlanningAgence.filter(
                (e) => e.id != moniteur.id
              );
            this.$store.commit("setMoniteursPlanningAgence", moniteursFilter);
            let moniteursArchive =
              this.$store.state.moniteursPlanningAgenceArchive;
            moniteursArchive.push(moniteur);
            this.$store.commit(
              "setMoniteursPlanningAgenceArchive",
              moniteursArchive
            );
            this.moniteursTable = this.moniteursTable.filter(
              (mono) => mono.id != moniteur.id
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .patch(
            "/utilisateur/" + moniteur.id,
            {
              active: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            let moniteursFilter =
              this.$store.state.moniteursPlanningAgenceArchive.filter(
                (e) => e.id != moniteur.id
              );
            this.$store.commit(
              "setMoniteursPlanningAgenceArchive",
              moniteursFilter
            );
            let moniteurs = this.$store.state.moniteursPlanningAgence;
            moniteurs.push(moniteur);
            this.$store.commit("setMoniteursPlanningAgence", moniteurs);
            this.moniteursTable = this.moniteursTable.filter(
              (mono) => mono.id != moniteur.id
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    met(e) {
      let checked = false;
      if (e.target.checked) {
        checked = true;
      }
      let id = e.target.id;
      let moniteur = this.$store.state.moniteursPlanningAgence.find(
        (e) => e.idMono == id
      );
      this.$emit("moniteurChoicePlanning", moniteur, checked);
    },
  },
};
</script>

<style scoped>
div.headerModal button.add {
  margin-right: 5em;
}
</style>
