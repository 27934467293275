<template>
  <template v-if="checkLoad">
    <div id="acceuilContainer" class="container-fluid">
      <template v-if="this.$store.state.user !== null">
        <template v-if="this.$store.state.user.role.label == 'eleve'">
          <div id="header">
            <div class="dropdown">
              <img
                class="dropbtn mt-4"
                id="iconNav"
                :src="require('../assets/images/iconNavb.png')"
              />

              <div class="dropdown-content">
                <router-link to="/JourneePorteOuverteEleve"
                  ><a href="#">Evénements</a></router-link
                >
                <router-link
                  v-if="this.contratExist == true"
                  :to="{
                    name: 'DonneePerso',
                    params: { personalData: 'true' },
                  }"
                  ><a href="#">Données Personnelles</a>
                </router-link>
                <a href="#" @click="deconnexion">Déconnexion</a>
              </div>
            </div>
            <h1 id="titreAccueil">Monpermis.fr</h1>
            <p id="nameUser" class="mt-4" style="color: white">
              {{ this.$store.state.user.nom }}
              {{ this.$store.state.user.prenom }}
            </p>
          </div>
          <div class="wrap mt-1" v-if="this.contratExist == false">
            <p id="txtForm">
              Merci de compléter le formulaire. Vous pourrez ensuite accéder aux
              fonctionnalités ci-dessous !
            </p>
            <button class="buttonShin mt-2" @click="goToForm()">
              FORMULAIRE
            </button>
          </div>
          <div id="navigation">
            <div class="containerMainNav">
              <template v-for="m in studentMenu" :key="m.id">
                <div class="mainNavItem" @click="navigation(m)">
                  <p>{{ m.name }}</p>

                  <p
                    v-if="
                      m.name === 'Dossier' &&
                      this.$store.state.user.role.label == 'eleve'
                    "
                    id="statutDossier"
                  >
                    <template v-if="this.dossier !== null">
                      <template v-if="this.dossier.numNeph != ''">
                        Enregistré : {{ this.dossier.numNeph }}
                      </template>
                      <template v-else>
                        {{ this.dossier.statutDossier }}
                      </template>
                    </template>
                  </p>

                  <p
                    v-if="
                      m.name === 'Code' &&
                      this.$store.state.user.role.label == 'eleve'
                    "
                    id="statutDossier"
                  >
                    <template v-if="successCode == true"> Obtenu </template>
                  </p>
                </div>
              </template>
            </div>
            <div class="wrap mt-3">
              <p id="txtev">Découvrez nos prochains événements !</p>
              <router-link to="/JourneePorteOuverteEleve"
                ><button class="buttonShin mt-2">
                  Evenements
                </button></router-link
              >
            </div>
          </div>

          <div id="navigationMobile" class="mt-3">
            <div class="wrap mt-3">
              <p id="txtev">
                Il existe des évenements dans les jours à venir !
              </p>
              <router-link to="/JourneePorteOuverteEleve"
                ><button class="button mt-2 mb-5" id="btnEvenements">
                  Evenements
                </button></router-link
              >
            </div>
            <div class="navigationItem" @click="goToDossier()">
              <div>
                <h2>Votre dossier</h2>
                <p>Voir votre dossier</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/imgdosse.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToCode()">
              <div>
                <h2>Code de la route</h2>
                <p>Révisez votre code</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/code2.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Examen de code</h2>
                <p>Réservez votre examen</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/ExaCode.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <router-link to="Calendrier" style="text-decoration: none">
                  <h2>Conduite</h2>
                  <p>Acceder à la conduite</p>
                </router-link>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/conduite.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Examen de conduite</h2>
                <p>Réservez votre examen de conduite</p>
              </div>
              <router-link to="/ReservationEx" style="text-decoration: none">
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/exaConduite.png')"
                  />
                </div>
              </router-link>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Edition du permis</h2>
                <p>Editez votre permis</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/etditionPerm.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Bons plans</h2>
                <p>Voir nos bons plans</p>
              </div>
              <div class="imgNav">
                <img class="mt-3" :src="require('../assets/images/like.png')" />
              </div>
            </div>
          </div>
        </template>

        <template v-if="this.$store.state.user.role.label == 'admin'">
          <div id="header">
            <div class="dropdown">
              <img
                class="dropbtn mt-4"
                id="iconNav"
                :src="require('../assets/images/iconNavb.png')"
              />

              <div class="dropdown-content">
                <router-link to="/Agence"><a href="#">Agence</a></router-link>
                <router-link to="/Lycee"><a href="#">Lycee</a></router-link>
                <router-link to="/Vehicule"
                  ><a href="#">Vehicule</a></router-link
                >
                <router-link to="/Moniteur"
                  ><a href="#">Moniteur</a></router-link
                >
                <router-link to="/LieuRDV"><a href="#">LieuRDV</a></router-link>
                <router-link to="/LieuEXAM"
                  ><a href="#">LieuExam</a></router-link
                >
                <router-link to="/Eleve"><a href="#">Eleve</a></router-link>
                <router-link to="/TrameFront"
                  ><a href="#">Trame</a></router-link
                >
                <router-link to="/Statistique"
                  ><a href="#">Statistique</a></router-link
                >
                <router-link to="/JourneePorteOuverte"
                  ><a href="#">Journee porte ouverte</a></router-link
                >
                <router-link to="/Fonctionnalite"
                  ><a href="#">Fonctionnalite</a></router-link
                >
                <router-link to="/admin/formationAac"
                  ><a href="#">Formation AAC</a></router-link
                >
                <router-link to="/OtherPaiementDeleted"
                  ><a href="#">Paiements supprimés</a></router-link
                >
                <a href="#" @click="deconnexion">Déconnexion</a>
              </div>
            </div>
            <h1 id="titreAccueil">Monpermis.fr</h1>
            <p id="nameUser" class="mt-4" style="color: white">
              {{ this.$store.state.user.nom }}
              {{ this.$store.state.user.prenom }}
            </p>
          </div>

            <div class="buttonActionShortCut">
              <div class="buttonAction">
                <button @click="openModalPaiementCpf" class="btn btn-primary">Saisir un paiement</button>
                <button @click="openModalListePaiements" class="btn btn-primary">Liste des paiements</button>
                <button @click="openModalListeCpf" class="btn btn-primary">Liste des CPF</button>
                <a :href="listSoldeExcel" class="btn btn-primary"  download="soldes.csv">Liste des soldes</a>
              </div>


              <Dialog v-model:visible="showModalCpf" modal header="Liste des CPF à partir d'une date donnée" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <input type="date" name="dateDeb" id="dateDeb" v-model="dateStartCpf" @click="getListeCpf"/>
                <br>
                <br>

                <a :href="listCpfExcel" class="btn btn-primary" v-if="listCpfExcel != null"  download="cpfs.csv">Liste des CPF</a>
              </Dialog>

              <Dialog v-model:visible="showModalPaiements" modal header="Liste des paiements entre deux dates données" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <input type="date" name="dateDeb" id="dateDeb" v-model="dateStartPaiement"/>
                <input type="date" name="dateEnd" id="dateEnd" v-model="dateEndPaiement" @click="getListePaiements"/>
                <br>
                <br>

                <a :href="listPaiementsExcel" class="btn btn-primary" v-if="listPaiementsExcel != null"  download="paiements.csv">Liste des paiements</a>
              </Dialog>


              <CpfModal :modalCpf="modalCpf" @closeModalPaiementCpf="closeModalPaiementCpf" v-if="modalCpf == true"/>
            </div>


          <div id="navigation">
            <div class="containerMainNav">
              <template v-for="m in topMenu" :key="m.id">
                <div class="topNavItem" @click="navigation(m)">
                  <p>{{ m.name }}</p>
                </div>
              </template>
            </div>

            <div class="containerMainNav">
              <template v-for="m in mainMenu" :key="m.id">
                <template v-if="m.name == 'Conduite'">
                  <div class="mainNavItem" @click="navigation(m)">
                    <p>{{ m.name }}</p>
                  </div>
                </template>
                <template v-else>
                  <div class="mainNavItem" @click="navigation(m)">
                    <p>{{ m.eleve }}</p>
                    <p>{{ m.name }}</p>
                    <p>{{ m.desc }}</p>
                    <p>{{ m.prix }} €</p>
                  </div>
                </template>
              </template>
            </div>
          </div>

          <div id="navigationMobile" class="mt-3" @click="goToDossier()">
            <div class="navigationItem">
              <div>
                <h2>Votre dossier</h2>
                <p>Voir votre dossier</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/imgdosse.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToCode()">
              <div>
                <h2>Code de la route</h2>
                <p>Révisez votre code</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/code2.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Examen de code</h2>
                <p>Réservez votre examen</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/ExaCode.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Conduite</h2>
                <p>Acceder à la conduite</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/conduite.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Examen de conduite</h2>
                <p>Réservez votre examen de conduite</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/exaConduite.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Edition du permis</h2>
                <p>Editez votre permis</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/etditionPerm.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToInProgress()">
              <div>
                <h2>Bons plans</h2>
                <p>Voir nos bons plans</p>
              </div>
              <div class="imgNav">
                <img class="mt-3" :src="require('../assets/images/like.png')" />
              </div>
            </div>
          </div>
        </template>

        <template v-if="this.$store.state.user.role.label == 'secretaire'">
            <div id="header">
              <div class="dropPos">
                <DropdownNav/>
              </div>
              

              <h1 id="titreAccueil">Monpermis.fr</h1>
              <div id="profile">
                <p id="nameUser" class="mt-4" style="color: white">
                  {{ this.$store.state.user.nom }}
                  {{ this.$store.state.user.prenom }}
                </p>
              </div>
            </div>
            
            <div class="buttonActionShortCut">
              <div class="buttonAction">
                <button @click="openModalPaiementCpf" class="btn btn-primary">Saisir un paiement</button>
                <button @click="openModalListePaiements" class="btn btn-primary">Liste des paiements</button>
                <button @click="openModalListeCpf" class="btn btn-primary">Liste des CPF</button>
                <a :href="listSoldeExcel" class="btn btn-primary"  download="soldes.csv">Liste des soldes</a>
              </div>


              <Dialog v-model:visible="showModalCpf" modal header="Liste des CPF à partir d'une date donnée" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <input type="date" name="dateDeb" id="dateDeb" v-model="dateStartCpf" @click="getListeCpf"/>
                <br>
                <br>

                <a :href="listCpfExcel" class="btn btn-primary" v-if="listCpfExcel != null"  download="cpfs.csv">Liste des CPF</a>
              </Dialog>

              <Dialog v-model:visible="showModalPaiements" modal header="Liste des paiements entre deux dates données" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                <input type="date" name="dateDeb" id="dateDeb" v-model="dateStartPaiement"/>
                <input type="date" name="dateEnd" id="dateEnd" v-model="dateEndPaiement" @click="getListePaiements"/>
                <br>
                <br>

                <a :href="listPaiementsExcel" class="btn btn-primary" v-if="listPaiementsExcel != null"  download="paiements.csv">Liste des paiements</a>
              </Dialog>


              <CpfModal :modalCpf="modalCpf" @closeModalPaiementCpf="closeModalPaiementCpf" v-if="modalCpf == true"/>
            </div>



            <div id="navigation">
              <div class="containerMainNav">
                <template v-for="m in topMenu" :key="m.id">
                  <div class="topNavItem" @click="navigation(m)">
                    <p>{{ m.name }}</p>
                  </div>
                </template>
              </div>

              <div class="containerMainNav">
                <template v-for="m in mainMenu" :key="m.id">
                  <template v-if="m.name == 'Conduite'">
                    <div class="mainNavItem" @click="navigation(m)">
                      <p>{{ m.name }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mainNavItem" @click="navigation(m)">
                      <p>{{ m.name }}</p>
                    </div>
                  </template>
                </template>
              </div>
            </div>

            <div id="navigationMobile" class="mt-3">
              <div class="navigationItem" @click="goToDossier()">
                <div>
                  <h2>Votre dossier</h2>
                  <p>Voir votre dossier</p>
                </div>
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/imgdosse.png')"
                  />
                </div>
              </div>

              <div class="navigationItem" @click="goToCode()">
                <div>
                  <h2>Code de la route</h2>
                  <p>Révisez votre code</p>
                </div>
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/code2.png')"
                  />
                </div>
              </div>

              <div class="navigationItem" @click="goToInProgress()">
                <div>
                  <h2>Examen de code</h2>
                  <p>Réservez votre examen</p>
                </div>
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/ExaCode.png')"
                  />
                </div>
              </div>

              <div class="navigationItem" @click="goToInProgress()">
                <div>
                  <router-link to="Calendrier" style="text-decoration: none">
                    <h2>Conduite</h2>
                    <p>Acceder à la conduite</p>
                  </router-link>
                </div>
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/conduite.png')"
                  />
                </div>
              </div>

              <div class="navigationItem" @click="goToInProgress()">
                <div>
                  <h2>Examen de conduite</h2>
                  <p>Réservez votre examen de conduite</p>
                </div>
                <router-link to="/ReservationEx" style="text-decoration: none">
                  <div class="imgNav">
                    <img
                      class="mt-3"
                      :src="require('../assets/images/exaConduite.png')"
                    />
                  </div>
                </router-link>
              </div>

              <div class="navigationItem" @click="goToInProgress()">
                <div>
                  <h2>Edition du permis</h2>
                  <p>Editez votre permis</p>
                </div>
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/etditionPerm.png')"
                  />
                </div>
              </div>

              <div class="navigationItem" @click="goToInProgress()">
                <div>
                  <h2>Bons plans</h2>
                  <p>Voir nos bons plans</p>
                </div>
                <div class="imgNav">
                  <img
                    class="mt-3"
                    :src="require('../assets/images/like.png')"
                  />
                </div>
              </div>
            </div>
        </template>

        <template v-if="this.$store.state.user.role.label == 'moniteur'">
          <headerMonPermis/>

          <div id="navigation">
            <div class="containerMainNav">
                <div class="topNavItem" @click="goToConduite()">
                  <p>PLANNING</p>
                </div>
                <div class="topNavItem" @click="navigation(m)">
                  <p>MA LISTE EXAM</p>
                </div>
            </div>

            <div class="containerMainNav">

              <div class="mainNavItem" @click="toRecapHeure()">
                <p>RECAP HEURES</p>
              </div>

              <div class="mainNavItem" @click="deconnexion">
                <p>DECONNEXION</p>
              </div>

            </div>
          </div>

          <div id="navigationMobile" class="mt-3" @click="goToConduite()">
            <div class="navigationItem">
              <div>
                <h2>Votre planning</h2>
                <p>Voir votre planning</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/planning.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="goToCode()">
              <div>
                <h2>Ma liste d'exam</h2>
                <p>Voir votre liste</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/examen.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="toRecapHeure()">
              <div>
                <h2>Recap de vos heures</h2>
                <p>Voir vos heures</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/heure.png')"
                />
              </div>
            </div>

            <div class="navigationItem" @click="deconnexion()">
              <div>
                <h2>Deconnexion</h2>
                <p>Deconnexion</p>
              </div>
              <div class="imgNav">
                <img
                  class="mt-3"
                  :src="require('../assets/images/se-deconnecter.png')"
                />
              </div>
            </div>
          </div>



        </template>
      </template>
    </div>
    <Dialog
      v-model:visible="showPopupForm"
      modal
      header="Formulaire de dossier"
      :style="{ width: '50vw' }"
    >
      <p>
        Merci de remplir le formulaire, vous pourrez ensuite accéder aux
        fonctionnalités du site.
      </p>

      <button @click="goToForm()" class="btn btn-primary">Formulaire</button>
    </Dialog>
  </template>

  <template v-else>
    <Loader />
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";
import CpfModal from "@/components/agence/CpfModal.vue";
import headerMonPermis from "@/components/headerMonPermis.vue";
import DropdownNav from "@/components/DropdownNav.vue";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      showPopupForm: false,
      menu: [
        { name: "Dossier" },
        { name: "Code" },
        { name: "Examen de Code" },
        { name: "Conduite" },
        { name: "Examen de Conduite" },
        { name: "Edition de Permis" },
        { name: "Bon Plans" },
      ],

      mainMenu: [],

      studentMenu: [
        { name: "Dossier" },
        { name: "Code" },
        { name: "Conduite" },
        { name: "Edition de Permis" },
      ],

      topMenu: [
        { name: "Agence" },
        { name: "Mono" },
        { name: "Ex. de Code" },
        { name: "Ex. de Conduite" },
      ],

      dossier: null,
      otherPaiement: null,
      contratExist: false,
      successCode: false,
      skipSec: 0,
      listSoldeExcel : null,
      showModalCpf : false,
      listCpfExcel : null,
      dateStartCpf : null,
      showModalPaiements : false,
      listPaiementsExcel : null,
      dateStartPaiement : null,
      dateEndPaiement : null,
      modalCpf : false,
    };
  },
  components: {
    Loader,
    DropdownNav,
    headerMonPermis,
    CpfModal
    
  },
  mounted() {
    this.getListeSoldes();
    this.getOtherPaiement();
    this.getPaiement();
    this.getFonctionnalite();
    this.getInfoFront();
    this.getIfContractExist();
    this.checkIfStudenntBuyDossier();
    this.checkIfStudenntBuyCode();
    if (this.$store.state.user.role.label == "eleve") {
      if (
        this.$store.state.user.dossier !== null &&
        !this.checkOnePaiement("dossier")
      ) {
        this.loadDossier();
        this.getIfContractExist();
      }
    }
  },
  methods: {
    ...mapActions(["getPaiement", "getFonctionnalite"]),

    openModalPaiementCpf(){
      this.modalCpf = true;
    },

    closeModalPaiementCpf(){
      this.modalCpf = false;
    },

    openModalListeCpf(){
      this.showModalCpf = true;
    },

    openModalListePaiements(){
      this.showModalPaiements = true;
    },

    getListePaiements(){
      if(this.dateStartPaiement != null){
        window.api
          .post(
            "/paiement/list/excel/"+this.$store.state.user.id,
            {
              start: moment(this.dateStartPaiement).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(this.dateEndPaiement).format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            let csv = response.data.liste;
            window.URL = window.URL || window.webkiURL;
            var blob = new Blob([csv]);
            var blobURL = window.URL.createObjectURL(blob);
            this.listPaiementsExcel = blobURL;
            this.dateEndPaiement = null;
            this.dateStartPaiement = null;
          })
          .catch((error) => {
            console.log(error);
          });
      }else{
        alert("Veuillez selectionner une date de début");
      }

    },


    getListeCpf(){
        window.api
          .post(
            "/paiement/cpf/list/excel/"+this.$store.state.user.id,
            {
              start: moment(this.dateStartCpf).format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            let csv = response.data.liste;
            window.URL = window.URL || window.webkiURL;
            var blob = new Blob([csv]);
            var blobURL = window.URL.createObjectURL(blob);
            this.listCpfExcel = blobURL;
            this.dateStartCpf = null;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getListeSoldes(){
      window.api
        .get("/eleve/solde/excel/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          let csv = response.data.liste;
          window.URL = window.URL || window.webkiURL;
          var blob = new Blob([csv]);
          var blobURL = window.URL.createObjectURL(blob);
          this.listSoldeExcel = blobURL;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },


    checkIfStudenntBuyDossier() {
      window.api
        .get("/student/" + this.$store.state.user.id + "/paiement/dossier", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$store.commit("setStudentDossier", response.data.paid);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    checkIfStudenntBuyCode() {
      window.api
        .get("/student/" + this.$store.state.user.id + "/paiement/code", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$store.commit("setStudentCode", response.data.paid);
          this.successCode = response.data.success;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getIfContractExist() {
      window.api
        .get("/student/" + this.$store.state.user.id + "/contract/exist", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.contratExist = response.data.contrat;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCheckIfPaiementExist(label) {
      window.api
        .get("/student/" + this.$store.state.user.id + "/paiement/" + label, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          return response.data.paid;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getInfoFront() {
      let id = 0;
      if (this.$store.state.user.role.label == "secretaire") {
        id = this.$store.state.user.agence.id;
      }

      window.api
        .get("/home/stat/" + id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.mainMenu = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getContract() {
      window.api
        .get("/contract/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.contract = response.data.checkContract;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadDossier() {
      window.api
        .post(
          "/dossier/home",
          {
            dossierId: this.$store.state.user.dossier.id,
            dossier: {},
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.dossier = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getOtherPaiement() {
      window.api
        .get("/other_paiements", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.otherPaiement = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    navigation(navItem) {
      switch (navItem.name) {
        case "Agence":
          this.$router.push({ name: "Donnees" });
          break;
        case "Mono":
          this.$router.push({ name: "AgenceMoniteur" });
          break;
        case "Dossier":
          this.goToDossier();
          break;
        case "Inscription":
          this.goToDossier();
          break;
        case "Formulaire":
          this.goToEleve();
          break;
        case "Conduite":
          this.goToConduite();
          break;
        case "Code":
          this.goToCode();
          break;
        case "Examen de Code":
          this.$router.push({ name: "adminExamCode" });
          break;
        case "Ex. de Code":
          this.goToGestionExamCode();
          break;
        case "Examen de Conduite":
          this.goToInProgress();
          break;
        case "Edition de Permis":
          this.goToInProgress();
          break;
        case "Bon Plans":
          this.goToInProgress();
          break;

        default:
          console.log(`Sorry`);
      }
    },

    deconnexion() {
      this.$store.commit("setUser", null);
      this.$store.commit("setToken", null);
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_access");
      this.$router.push({name: 'index'});
    },

    toRecapHeure(){
      this.$router.push({name: 'mono_heure'});
    },

    checkOnePaiement(label) {
      if (
        this.paiement !== null &&
        this.otherPaiement !== null &&
        this.fonctionnalite !== null &&
        this.otherPaiement["hydra:member"] !== []
      ) {
        let sommeOtherPaiement = 0;
        let filterOtherPaiement = this.otherPaiement["hydra:member"].filter(
          (el) => el.description.toLowerCase() == label.toLowerCase()
        );

        if (filterOtherPaiement.length > 0) {
          filterOtherPaiement = filterOtherPaiement.filter(
            (el) =>
              el.eleve === "/api/utilisateurs/" + this.$store.state.user.id
          );
          if (filterOtherPaiement.length > 0) {
            filterOtherPaiement = filterOtherPaiement.filter(
              (el) => el.archive === false
            );
            if (filterOtherPaiement.length > 0) {
              for (let i = 0; i < filterOtherPaiement.length; i++) {
                sommeOtherPaiement += filterOtherPaiement[i].totalAmount;
              }
            }
          }
        }

        let pForm = this.paiement.filter((el) => el.etat === "payé");

        pForm = pForm.filter(
          (el) =>
            el.description.substring(0, 7).toLowerCase() == label.toLowerCase()
        );

        if (pForm.length === 0) {
          return true;
        }

        return (
          (this.paiement.filter((el) => el.description === label).length > 0 &&
            this.paiement.filter((el) => el.etat === "payé").length > 0) ||
          !(
            sommeOtherPaiement / 100 <
            this.fonctionnalite.filter((el) => el.label === label)[0].montant
          )
        );
      }
      return false;
    },
    goToDossier() {
      if (this.$store.state.user.role.label === "eleve") {
        window.api
          .get("/student/" + this.$store.state.user.id + "/paiement/dossier", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            if (!response.data.paid) {
              if (this.contratExist == true && this.dossier == null) {
                this.$router.push({
                  name: "Dossier-paiement",
                });
              } else {
                if (this.contratExist == true && this.dossier.numNeph == "") {
                  this.$router.push({
                    name: "Dossier-paiement",
                  });
                } else {
                  if (this.dossier.numNeph === "") {
                    this.showPopupForm = true;
                  }
                }
              }
            } else {
              if (this.dossier !== null) {
                if (this.dossier.numNeph === "" && this.contratExist == true) {
                  this.$router.push({ name: "Piece" });
                }
              } else {
                if (this.contratExist == true && this.dossier.numNeph === "") {
                  this.$router.push({
                    name: "Dossier-paiement",
                    params: { personalData: "true" },
                  });
                } else {
                  if (this.dossier.numNeph === "") {
                    this.showPopupForm = true;
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else if (this.$store.state.user.role.label === "secretaire") {
        this.$router.push({ name: "AgenceDossier" });
      } else if (this.$store.state.user.role.label === "admin") {
        this.$router.push({ name: "AdminDossier" });
      }
    },

    goToForm() {
      this.$router.push({
        name: "Dossier",
        params: { personalData: "true" },
      });
    },

    goToEleve() {
      if (this.$store.state.user.role.label === "secretaire") {
        this.$router.push({ name: "AgenceEleve" });
      } else if (this.$store.state.user.role.label === "admin") {
        this.$router.push({ name: "Eleve" });
      }
    },
    goToCode() {
      if (
        this.contratExist == false &&
        this.$store.state.user.role.label === "eleve"
      ) {
        this.showPopupForm = true;
      }
      if (
        this.redirectDossierNeed &&
        this.$store.state.user.role.label === "eleve"
      ) {
        this.$store.state.displayRedirectDossier = true;
      } else {
        //  if (!this.checkOnePaiement("code")) {
        //    this.$router.push({ name: "CodeAccueil" });
        //  } else {
        //    this.$router.push({ name: "Code" });
        //  }
        if (
          this.$store.state.user.role.label === "eleve" &&
          this.contratExist == true
        ) {
          if (!this.successCode) {
            this.$router.push({ name: "CodeFront" });
          }
        } else if (this.$store.state.user.role.label === "admin") {
          this.$router.push({ name: "adminCode" });
        } else if (this.$store.state.user.role.label === "secretaire") {
          this.$router.push({ name: "secretaireCode" });
        }
      }
    },
    goToConduite() {
      if (this.$store.state.user.role.label === "secretaire") {
        this.$router.push({ name: "agence-planning" });
      } else if (this.$store.state.user.role.label === "eleve") {
        this.$router.push({ name: "eleve_conduite" });
      } else if (this.$store.state.user.role.label === "admin") {
        this.$router.push({ name: "admin-choix-planning" });
      } else if(this.$store.state.user.role.label === "moniteur"){
        this.$router.push({ name: "PlanningMonoFront" });
      }
    },
    goToExamenCode() {
      if (
        this.redirectDossierNeed &&
        this.$store.state.user.role.label === "eleve"
      ) {
        this.$store.state.displayRedirectDossier = true;
      } else {
        this.$router.push({ name: "InProgress" });
      }
    },

    goToGestionExamCode() {
      if (this.$store.state.user.role.label === "secretaire") {
        this.$router.push({ name: "examCode" });
      } else {
        this.$router.push({ name: "adminExamCode" });
      }
    },

    goToInProgress() {
      if (
        this.$store.state.user.role.label === "eleve" &&
        this.contratExist == false
      ) {
        this.showPopupForm = true;
      } else {
        this.$router.push({ name: "InProgress" });
      }
    },
    goToEditionPermis() {
      if (
        this.redirectDossierNeed &&
        this.$store.state.user.role.label === "eleve"
      ) {
        this.$store.state.displayRedirectDossier = true;
      } else {
        this.$router.push({ name: "InProgress" });
      }
    },
    goToExamenConduite() {
      if (
        this.redirectDossierNeed &&
        this.$store.state.user.role.label === "eleve"
      ) {
        this.$store.state.displayRedirectDossier = true;
      } else {
        this.$router.push({ name: "InProgress" });
      }
    },
    goToBonPlans() {
      this.$router.push({ name: "InProgress" });
    },
  },
  computed: {
    ...mapGetters(["paiement", "fonctionnalite", "redirectDossierNeed"]),
    checkLoad() {
      return (
        this.otherPaiement !== null &&
        this.paiement !== null &&
        this.fonctionnalite !== null
      );
    },
  },
};
</script>

<style scoped>

div.buttonActionShortCut div.buttonAction button{
  margin: 1em;
}

div.buttonActionShortCut div.buttonAction a{
  margin: 1em;
}

div#header div.dropPos{
  margin-top: 2em;
}

p#txtForm {
  color: white;
  font-size: 30px;
  font-weight: bold;
}
#navigation div.containerMainNav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 2em;
}

#navigation div.containerMainNav div.topNavItem:hover {
  border: 1px solid;
  border-color: rgba(0, 150, 203, 0.7);
  cursor: pointer;
}

#navigation div.containerMainNav div.mainNavItem:hover {
  border: 1px solid;
  border-color: rgba(0, 150, 203, 0.7);
  cursor: pointer;
}

#navigation div.containerMainNav div.topNavItem {
  background: rgba(255, 255, 255, 0.4);
  width: 20%;
  height: 5vh;
  padding: 4%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

#navigation div.containerMainNav div.mainNavItem {
  background: rgba(255, 255, 255, 0.4);
  width: 20%;
  height: 38vh;
  padding: 4%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

#statutDossier {
  position: absolute;
  bottom: 4%;
  left: 4%;
  font-weight: normal !important;
  font-size: 15px !important;
  height: 25px;
  margin-bottom: 0;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.buttonShin {
  min-width: 300px;
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #4fd1c5;
  background: linear-gradient(
    90deg,
    rgba(129, 230, 217, 1) 0%,
    rgba(79, 209, 197, 1) 100%
  );
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(79, 209, 197, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

button.buttonShin::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #00ffcb;
  box-shadow: 0 0 60px rgba(0, 255, 203, 0.64);
  position: absolute;
  animation: ring 4s infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.buttonShin:hover,
.buttonShin:focus {
  color: #313133;
  transform: translateY(-6px);
}

button.buttonShin:hover::before,
button.buttonShin:focus::before {
  opacity: 1;
}

button.buttonShin::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button.buttonShin:hover::after,
button.buttonShin:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }

  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

#navDiv {
  background: rgb(255, 255, 255, 0.4);
  width: 95%;
  height: 38vh;
  padding: 4%;
  margin: auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: relative;
}

#navDiv:hover {
  border: 3px solid #0798a0;
}

#navigation {
  margin-top: 8%;
}

#navItem {
  color: white;
}

#profile {
  display: flex;
  flex-direction: row;
}

#titreAccueil {
  position: center;
  color: white;
  font: normal normal 600 76px/103px Galano Grotesque Alt;
  text-align: center;
}

#acceuilContainer {
  min-height: 100vh;
  height: auto;
  background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
    0% no-repeat padding-box !important;
  width: 100%;
}

#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#iconNav {
  width: 40px;
  height: 40px;
}

#blueCircle {
  width: 170px;
  height: 170px;
}

#navigation > div p {
  text-align: center;
  font: normal normal bold 30px/30px Arial;
  letter-spacing: 0px;
  color: #fbfbfb;
}

footer {
  height: 45px;
  background: #0798a0 0% 0% no-repeat padding-box;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 2%;
}

#navigationMobile {
  display: none;
}

/* Style The Dropdown Button */
.dropbtn {
  color: white;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  border-radius: 17px;
  background: transparent linear-gradient(131deg, #de6fa8 0%, #fbbb5b 100%) 0%
    0% no-repeat padding-box;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  border-radius: 17px;
  background: transparent linear-gradient(131deg, #de6fa8 0%, #fbbb5b 100%) 0%
    0% no-repeat padding-box;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #navigationMobile {
    display: flex;
    flex-direction: column;
    background: rgb(251, 187, 91, 0.1);
    padding: 2%;
    width: 95%;
    margin: auto;
  }

  .navigationItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2%;
  }

  #navigation {
    display: none;
  }

  #titreAccueil {
    font-size: 2.7rem;
    color: #de6fa8;
  }

  #acceuilContainer {
    min-height: 100vh;
    height: auto;
    background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
      0% no-repeat padding-box !important;
    width: 100%;
  }

  #header {
    width: 100vw;
    background-color: lightblue;
  }

  #acceuilContainer {
    background: url("../assets/images/fond2.png") !important;
    background-size: cover;
    background-position: center, center;
  }

  /* Bootstrap qui fais de la merde en ajoutant un padding left ducoup je l'enleve */
  .container-fluid {
    padding: 0px;
  }

  #navigationMobile > div img {
    width: 60px;
    height: 60px;
    position: relative;
    bottom: 14px;
    background-color: #de6fa8;
    padding: 20%;
    bottom: 14px;
  }

  #navigationMobile > div h2,
  p {
    text-align: left;
  }

  #navigationMobile > div h2 {
    color: #fbbb5b;
  }

  #navigationMobile > div p {
    color: #07989f;
  }

  footer {
    display: none;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  #btnEvenements {
    width: 200px !important;
  }

  #navigationMobile {
    display: flex;
    flex-direction: column;
    background: rgb(251, 187, 91, 0.1);
    padding: 4%;
    width: 90%;
    margin: auto;
  }

  #navigation {
    display: none;
  }

  #nameUser {
    display: none;
  }

  #header {
    width: 100vw;
    justify-content: space-evenly;
    background-color: lightblue;
  }

  #titreAccueil {
    position: center;
    color: #de6fa8;
    background-color: lightblue;
    font: normal normal 600 40px/70px Galano Grotesque Alt;
    text-align: center;
  }

  #acceuilContainer {
    background: url("../assets/images/fond2.png") !important;
    background-size: cover;
    background-position: center, center;
  }

  /* Bootstrap qui fais de la merde en ajoutant un padding left ducoup je l'enleve */
  .container-fluid {
    padding: 0px;
  }

  #iconNav {
    width: 30px;
    height: 30px;
  }

  #navigationMobile > div img {
    width: 60px;
    height: 60px;
    position: relative;
    bottom: 14px;
    background-color: #de6fa8;
    padding: 20%;
  }

  .navigationItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  #navigationMobile > div h2,
  p {
    text-align: left;
  }

  #navigationMobile > div h2 {
    color: #fbbb5b;
  }

  #navigationMobile > div p {
    color: #07989f;
  }

  footer {
    display: none;
  }
}
</style>
